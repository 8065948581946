import React from 'react';
import { DrawerContextProvider } from '../src/contexts/DrawerContext';
import { ThemeContextProvider } from '../src/contexts/ThemeContext';
import { NotificationModalContextProvider } from '../src/contexts/NotificationModalContext';
import { BrowserRouter as Router } from 'react-router-dom';

export function wrapRootElement({ element }) {
  return (
    <ThemeContextProvider>
      <DrawerContextProvider>
          {element}
      </DrawerContextProvider>
    </ThemeContextProvider>
  );
}