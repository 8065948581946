import { createMuiTheme } from '@material-ui/core/styles';
import defaultTheme from './default';

const theme = createMuiTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme?.palette,
    primary: {
      ...defaultTheme?.palette?.primary,
      main: '#1A1738',
      bgLight: '#8645FF',
      bgMain: '#3A2870',
    },
    background: {
      main: '#1A1738',
    },
    text: {
      ...defaultTheme?.palette?.text,
      primary: '#DED6F8',
    },
    action: {
      ...defaultTheme.palette.action,
      disabledBackground: '#F5F2FF',
    },
    secondary: {
      ...defaultTheme?.palette.secondary,
    },
  },
});

export default theme;
