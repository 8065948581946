import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        boxShadow: 'none !important',
      },
      label: {
        textTransform: 'initial',
      },
      contained: {
        padding: '10px 25px',
        '&.Mui-disabled': {
          color: '#fff',
        },
      },
      outlined: {
        padding: '10px 25px',
      },
      text: {
        padding: '10px 25px',
      },
    },
    MuiLinearProgress: {
      root: {
        height: 5,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor: '#cccbd2',
      },
      bar: {
        borderRadius: 5,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 15,
        boxShadow: '0px 0px 0px -3px rgba(0,0,0,0.2), 0px 5px 5px 1px rgba(0,0,0,0.14), 0px 0px 5px 0px rgba(0,0,0,0.12)',
      },
    },
    MuiInputBase: {
      root: {
        '& .MuiSelect-root:focus': {
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
      input: {
        padding: '6px 1px 7px',

        '&:-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 30px white inset',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          borderColor: '#573da8 !important',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(24px, 20px) scale(1)',
      },
    },
    MuiAlert: {
      root: {
        borderRadius: 0,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 15,
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 15,
      },
    },
    MuiPopover: {
      paper: {
        maxHeight: '300px !important',
      },
    },
    MuiAutocomplete: {
      input: {
        paddingLeft: '15px !important',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
      },
    },
    MuiLink: {
      root: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': {
          fontDisplay: 'swap',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#573da8',
      lightest: '#CBC0F0',
      alpha: 'rgba(87, 61, 168, 0.25)',
      highlight: '#CB57F5',
      dark: '#3A2870',
      bgDark: '#1A1738',
      light: '#DED6F8',
      bgLight: '#7C2E7E',
      bgMain: '#7C2E7E',
    },
    background: {
      main: '',
    },
    tnm: {
      main: '#8645FF',
    },
    secondary: {
      main: '#f17700',
      lightest: '#FFEBDB',
      contrastText: '#ffffff',
    },
    error: {
      main: '#d62046',
      lightest: '#FFD1DA',
      alpha: 'rgba(214, 32, 70, 0.25)',
    },
    warning: {
      main: '#f17700',
      lightest: '#FFEBDB',
      alpha: 'rgba(241, 119, 0, 0.25)',
    },
    info: {
      main: '#44a0db',
      light: '#D2E7F4',
      lightest: '#BCE5FF',
      alpha: 'rgba(68, 160, 219, 0.2)',
      highlight: '#31AEFF',
    },
    success: {
      main: '#48ae9f',
      lightest: '#7CDF64',
      alpha: 'rgba(172, 221, 216, 0.5)',
      highlight: '#40E5BE',
    },
    text: {
      primary: '#383743',
      lightest: '#C9C9C9',
    },
    danger: {
      main: '#000',
    },
    white: {
      main: '#F4F3F1',
      almost: '#F7F7FA',
    },
    midGrey: {
      main: '#66647A',
      soft: '',
    },
    action: {
      disabledBackground: '#cccbd2',
    },
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: 50,
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    h1: {
      fontSize: 69,
      fontWeight: 'bold',
      color: '#383743',
    },
    h2: {
      fontSize: 53,
      fontWeight: 300,
      color: '#383743',
    },
    h3: {
      fontSize: 40,
      fontWeight: 300,
      color: '#383743',
    },
    h4: {
      fontSize: 31,
      color: '#383743',
    },
    h5: {
      fontSize: 24,
      color: '#383743',
    },
    h6: {
      fontSize: 20,
      fontWeight: 400,
      color: '#383743',
    },
    subtitle1: {
      fontSize: 16,
      letterSpacing: 1.04,
      lineHeight: 1.25,
    },
    subtitle2: {
      fontSize: 13,
      letterSpacing: 0.58,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.51,
      color: '#66647a',
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.51,
      fontWeight: 300,
      color: '#383743',
    },
    button: {
      fontSize: 14,
      letterSpacing: 0.6,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 14,
      fontWeight: 300,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 2,
      textTransform: 'uppercase',
      marginTop: '10px',
    },

    '@media (max-width: 959px)': {
      h1: {
        fontSize: 22,
      },
      h2: {
        fontSize: 20,
      },
      h3: {
        fontSize: 22,
      },
      h4: {
        fontSize: 22,
      },
      h6: {
        fontSize: 16,
      },
      subtitle2: {
        fontWeight: 'bold',
      },
    },
  },
});

export default theme;
