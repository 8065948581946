// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-abrir-empresa-js": () => import("./../../../src/pages/abrir-empresa.js" /* webpackChunkName: "component---src-pages-abrir-empresa-js" */),
  "component---src-pages-comercio-abrir-empresa-js": () => import("./../../../src/pages/comercio/abrir-empresa.js" /* webpackChunkName: "component---src-pages-comercio-abrir-empresa-js" */),
  "component---src-pages-comercio-transformacao-mei-microempresa-js": () => import("./../../../src/pages/comercio/transformacao-mei-microempresa.js" /* webpackChunkName: "component---src-pages-comercio-transformacao-mei-microempresa-js" */),
  "component---src-pages-comercio-trocar-de-contador-js": () => import("./../../../src/pages/comercio/trocar-de-contador.js" /* webpackChunkName: "component---src-pages-comercio-trocar-de-contador-js" */),
  "component---src-pages-condicoes-de-impedimentos-js": () => import("./../../../src/pages/condicoes-de-impedimentos.js" /* webpackChunkName: "component---src-pages-condicoes-de-impedimentos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mapa-do-site-js": () => import("./../../../src/pages/mapa-do-site.js" /* webpackChunkName: "component---src-pages-mapa-do-site-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-precos-js": () => import("./../../../src/pages/precos.js" /* webpackChunkName: "component---src-pages-precos-js" */),
  "component---src-pages-quanto-custa-index-js": () => import("./../../../src/pages/quanto-custa/index.js" /* webpackChunkName: "component---src-pages-quanto-custa-index-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-solucoes-js": () => import("./../../../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-termos-e-condicoes-js": () => import("./../../../src/pages/termos-e-condicoes.js" /* webpackChunkName: "component---src-pages-termos-e-condicoes-js" */),
  "component---src-pages-trocar-contador-js": () => import("./../../../src/pages/trocar-contador.js" /* webpackChunkName: "component---src-pages-trocar-contador-js" */),
  "component---src-pages-trocar-contador-wdec-js": () => import("./../../../src/pages/trocar-contador/wdec.js" /* webpackChunkName: "component---src-pages-trocar-contador-wdec-js" */)
}

