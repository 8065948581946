import React, { useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { ThemeProvider } from '@material-ui/core';
import defaultTheme from '../themes/default';
import purpleFriday from '../themes/purpleFriday';

const THEMES = {
  defaultTheme,
  purpleFriday,
};

export const ThemeContext = createContext(defaultTheme);

export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);

  function changeTheme(name) {
    setTheme(THEMES[name]);
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        changeTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const theme = useContextSelector(ThemeContext, (themeContext) => themeContext.theme);
  const changeTheme = useContextSelector(ThemeContext, (themeContext) => themeContext.changeTheme);

  return {
    theme,
    changeTheme,
  };
};
