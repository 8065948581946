import React, { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { ModalNotification } from '../components/molecules/ModalNotification';

export const NotificationModalContext = createContext({});

export const NotificationModalContextProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  }

  const showAndResetModal = () => {
    setOpenModal(true);
  }

  useEffect(() => {
    showAndResetModal();
  }, []);

  useEffect(() => {
    if (openModal) return;

    const intervalId = setInterval(() => {
      showAndResetModal();
    }, 90000);

    return () => clearInterval(intervalId);
  }, [openModal])

  return (
    <NotificationModalContext.Provider>
      {children}
      <ModalNotification 
        actionButtonText='Fale com um especialista'
        imageLargeSrc='https://conube-example-public.s3.amazonaws.com/Frame+66.svg'
        imageSmallSrc='https://conube-example-public.s3.amazonaws.com/Frame+1+(2).svg'
        link='https://wa.me/5511995362311'
        onClose={handleClose}
        isOpen={openModal}
        title='Novembro Super Purple!'
      />
    </NotificationModalContext.Provider>
  );
};