import React, { useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

export const DrawerContext = createContext({});

export const DrawerContextProvider = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <DrawerContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = () => {
  const setOpenDrawer = useContextSelector(DrawerContext, (drawer) => drawer.setOpenDrawer);

  return {
    setOpenDrawer,
  };
};

export const useStateDrawerContext = () => {
  const openDrawer = useContextSelector(DrawerContext, (drawer) => drawer.openDrawer);

  return {
    openDrawer,
  };
};
